import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]
  static classes = [ "hidden" ]

  toggle() {
    this.itemTarget.classList.toggle(this.hiddenClass)
  }

  hide(event) {
    if(this.element === event.target || this.element.contains(event.target)) return
    this.itemTarget.classList.add(this.hiddenClass)
  }

  disconnect() {
    this.hide()
  }
}
