import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { copy: String }

  copy() {
    event.preventDefault()

    var data = [new ClipboardItem({ "text/plain": new Blob([this.copyValue], { type: "text/plain" }) })]
    navigator.clipboard.write(data)
      .then(function() {
        console.log("Copied to clipboard successfully!")
      }, function() {
        console.error("Unable to write to clipboard. :-(")
      })
  }
}
